import { Box, Card, CardContent, Container } from "@mui/material";

function Subscription() {

  return (
    <Container maxWidth={"lg"}>
      <Box className={"App"}>
        <Card sx={{ maxHeight: '90vh' }}>
          <CardContent>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}

export default Subscription();